@import url('https://fonts.googleapis.com/css2?family=Oxanium:wght@700&display=swap');

:root {
	--line-heigh-mainmenu: 60px;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
  color: #212529;
  height: 100vh;
  width: 100%;
  background-color: #f9f9f9;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

h1, h2, h5{
	margin: 0;
}
h5 {
	color: #b0b0b0;
}

@media (min-width: 700px) {
	.timer {
		box-shadow: 0 20px 18px rgb(41 39 89 / 25%);
	}
}
/* @media (max-width: 499px) {
	.wrap {
		height: calc(100vh - var(--line-heigh-mainmenu) - 1px);
		display: flex;
		flex-direction: column-reverse;
		justify-content: space-between;
	}
} */
/* @media (min-width: 500px) {
	.wrap {
		flex-direction: column;
	}
} */

.timer {
	display: flex;
    flex-direction: column;
	justify-content: flex-start;
	padding: 30px 20px;
	margin: 0 auto;
	min-height: 270px;
	max-width: 600px;
	position: relative;
}

.timer.center {
	align-items: center;
	padding: 30px 0;
}

.timer__items {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 48px;
}

.timer__item {
  position: relative;
  min-width: 46px;
  margin-left: 8px;
  margin-right: 8px;
  padding-bottom: 15px;
  text-align: center;
  font-family: 'Oxanium', cursive;
}

.timer__date {
  display: block;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  font-size: medium;
}

.timer__item:not(:last-child)::after {
  content: ':';
  position: absolute;
  right: -14px;
}



.mainmenu {
    background: #444;
    position: relative;
    overflow: hidden;
	border-bottom: 1px solid #777777;
}
.mainmenu ul {
	max-width: 600px;
    list-style: none;
    margin: 0 auto;
    padding: 0;
	display: flex;
    font: 18px Arial, Helvetica, sans-serif;
}
.mainmenu ul li {
	flex: auto;
}
.mainmenu ul li a {
    color: #fff;
    display: block;
    text-decoration: none;
    line-height: var(--line-heigh-mainmenu);
	text-align: center;
}
.mainmenu ul li a:hover {
    background-color: #666;
}
.mainmenu ul li a.active {
	background-color: #666;
}

.form {
	display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
	margin-bottom: 20px;
}
.form input {
	text-align: center;
	height: 34px;
	font-size: 16px;
	width: 50%;
}

fieldset{
	margin-bottom: 20px;
}

.wrap-button {
	margin-top: 20px;
    width: 80%;
    display: flex;
    justify-content: space-evenly;
}
.wrap-button .button:not(:last-child) {
    margin-right: 20px;
}

.button {
	background: #444444;
    color: white;
	font-size: 1rem;
    text-decoration: none;
    padding: 8px;
    border-radius: 4px;
    width: 100%;
    text-align: center;
	cursor: pointer;
}
.button:hover {
	background-color: #666;
}
.button:active {
	box-shadow: inset 0 0 20px black;
}
.timer > input {
	text-align: center;
    width: 100%;
    height: 34px;
	font-size: 16px;
	margin-bottom: 20px;
}

.absolute {
	position: absolute;
	left: 0;
    right: 0;
}
.error {
    padding: 20px;
    background: antiquewhite;
    margin-top: 20px;
    width: 100%;
    border-radius: 5px;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    color: red;
	box-shadow: 0 10px 20px rgb(41 39 89 / 25%);
	animation: slideIn 0.3s;
}
@keyframes slideIn {
	from {
		transform: translateY(30%);
	}
	to {
		transform: translateY(0);
	}
}

.center {
	margin: auto;
}
p {
	text-indent: 1.5em;
    margin: 10px 0;
    font-size: 20px;
	font-weight: 400;
}

.logo {
	width: 100px;
    text-align: center;
}
.logo img {
    width: 50px;
    margin-top: 2px;
}
